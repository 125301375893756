import * as React from 'react';
import { useInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const LabeledTextField = (props) => {
  const {
    labelAdornment, label, onChangeCall, disabled, isError, helperText,
  } = props;
  const {
    input,
    meta: { touched, error },
  } = useInput(props);
  return (
    <TextField
      style={{ marginBottom: '20px', marginRight: '2%' }}
      {...input}
      label={label}
      disabled={disabled}
      error={isError || !!(touched && error)}
      helperText={isError === true ? helperText : touched && error}
      onBlur={onChangeCall}
      InputProps={{
        endAdornment: <InputAdornment position="start">{labelAdornment}</InputAdornment>,
      }}
    />
  );
};
export default LabeledTextField;
