import { makeStyles } from '@material-ui/core/styles';

export const messageEanStyle = {
  color: 'white',
  fontSize: '1.2em',
  fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  backgroundColor: '#dc7b71',
  padding: '10px',
  borderRadius: '3px',
};

export const commentListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
    maxWidth: '80vw',
    maxHeight: '80vh',
  },
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
    textAlign: 'center',
  },
  rowCell: {
    padding: '4px',
  },
  comment: {
    maxWidth: '15em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  status: {
    textTransform: 'lowercase',
    textAlign: 'center',
  },
  country: {
    textAlign: 'center',
  },
  centerCell: {
    textAlign: 'center',
    maxWidth: '130px',
  },
  leftCell: {
    maxWidth: '130px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  smallCell: {
    width: '30px',
    textAlign: 'center',
  },
  expandedCell: {
    width: '10px',
    textAlign: 'center',
  },
  statusCell: {
    width: '180px',
  },
}));


export const commentEditStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
    '& > div': {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-evenly',
      alignContent: 'stretch',
      gap: '20px 20px',

      '& > div': {
        flex: '0 1 25%',
      },
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  commentColumnLeft: {
    width: '20vw',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'red',
  },
  commentColumnRight: {
    margin: '10px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'yellow',
  },
  rightColumn: {
    display: 'inline-block',
    width: '65%',
    wordBreak: 'break-all',
  },
  leftColumn: {
    display: 'inline-block',
    width: '35%',
  },
  status: {
    marginLeft: '-10px',
  },
}));
export const commentStatusStyles = makeStyles(theme => ({
  form: {
    height: '40px',
    maxWidth: '180px',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
    overflow: 'hidden',
  },
  status: {
    paddingTop: '2px',
    textTransform: 'lowercase',
    textAlign: 'center',
    height: '45px',
    position: 'relative',
    top: '-35px',
  },
  control: {
    border: '2px solid blue',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
    width: '2em !important',
    height: '2em !important',
  },
}));
