import React, {
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Route, useRouteMatch } from 'react-router-dom';

import { escapePath } from 'ra-core';

import TabbedLayoutTabs, { getTabFullPath } from './TabbedLayoutTabs';

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  staticContext,
  translate,
  tabs,
  ...rest
}) => rest;


/**
 * Tabbed Layout for a view tabs.
 * Children should be Tab components.
 * The component passed as `tabs` props replaces the default material-ui's <Tabs> component.
 *
 * @example
 *     // in src/posts.js
 *     import * as React from "react";
 *     import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin';
 *
 *     export const PostShow = (props) => (
 *             <TabbedLayout>
 *                 <Tab label="Content">
 *                    CONTENT NODE
 *                </Tab>
 *                 <Tab label="Metadata">
 *                    CONTENT NODE
 *                </Tab>
 *             </TabbedLayout>
 *     );
 *
 */
const TabbedLayout = (props) => {
  const {
    basePath,
    children,
    className,
    resource,
    version,
    value,
    tabs,
    ...rest
  } = props;
  const match = useRouteMatch();

  const nonNullChildren = Children.toArray(children).filter(
    child => child !== null,
  );

  return (
    <div className={className} key={version} {...sanitizeRestProps(rest)}>
      {cloneElement(tabs, {}, nonNullChildren)}

      <Divider />
      <div>
        {Children.map(nonNullChildren, (tab, index) => (tab && isValidElement(tab) ? (
          <Route
            exact
            path={escapePath(
              getTabFullPath(tab, index, match.url),
            )}
            render={() => cloneElement(tab, {
              context: 'content',
              resource,
              basePath,
            })
              }
          />
        ) : null))}
      </div>
    </div>
  );
};


TabbedLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  value: PropTypes.number,
  version: PropTypes.number,
  tabs: PropTypes.element,
};

TabbedLayout.defaultProps = {
  tabs: <TabbedLayoutTabs />,
  children: null,
  className: null,
  location: null,
  match: null,
  resource: null,
  basePath: null,
  value: null,
  version: null,
};

export default TabbedLayout;
