import ProductIcon from '@material-ui/icons/ShoppingCart';
import ProductList from './ProductList';
import ProductCreate from './ProductCreate';
import ProductEdit from './ProductEdit';

export default {
  list: ProductList,
  icon: ProductIcon,
  create: ProductCreate,
  edit: ProductEdit,
};
