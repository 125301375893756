import React from 'react';
import {
  Create,
  RadioButtonGroupInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import UserValidation from './UserValidation';

const UserCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="user.action.save_and_list"
      redirect="show"
      submitOnEnter
      data-testid="user-create-submit"
    />
  </Toolbar>
);

const UserCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <SimpleForm
      toolbar={<UserCreateToolbar />}
      data-testid="user-create-form"
    >
      <TextInput
        source="username"
        fullWidth
        validate={UserValidation.validateUsername}
        resettable
      />
      <TextInput
        source="name"
        fullWidth
        validate={UserValidation.validateName}
        resettable
      />
      <TextInput
        fullWidth
        source="lastName"
        validate={UserValidation.validateLastName}
        resettable
      />
      <RadioButtonGroupInput
        source="active"
        validate={UserValidation.validateActive}
        choices={[
          {
            id: 'true',
            name: 'Active',
          },
          {
            id: 'false',
            name: 'Inactive',
          },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
