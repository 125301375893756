import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  useNotify,
} from 'react-admin';

import Form from './Form';
import SurveyToolbar from './SurveyToolbar';

const SurveyEdit = (props) => {
  const notify = useNotify();
  const [input, setInput] = useState(undefined);
  const zero = 0;
  const onSuccess = () => {
    notify('surveys.edit.updated', 'success');
    window.scrollTo({ top: zero, behavior: 'smooth' });
  };

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      actions={null}
      resource="surveys"
      mutationMode="pessimistic"
    >
      <SimpleForm redirect="edit" toolbar={<SurveyToolbar isEdit input={input} />}>
        <Form isEdit setInput={setInput} {...props} />
      </SimpleForm>
    </Edit>
  );
};

export default SurveyEdit;
