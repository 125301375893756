import SurveyIcon from '@material-ui/icons/Assignment';

import SurveyList from './SurveyList';
import SurveyCreate from './SurveyCreate';
import SurveyEdit from './SurveyEdit';

export default {
  icon: SurveyIcon,
  list: SurveyList,
  create: SurveyCreate,
  edit: SurveyEdit,
};
