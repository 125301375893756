import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { GRAPHQL_API_URL } from '../lib/utils';
import RefreshMiddleware from './RefreshMiddleware';
import RefreshLink from './RefreshLink';

const cache = new InMemoryCache();
const uploadLink = createUploadLink({ uri: GRAPHQL_API_URL });

const client = new ApolloClient({
  link: ApolloLink.from([
    RefreshLink,
    RefreshMiddleware,
    uploadLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
