import { createTheme } from '@material-ui/core/styles';
import theme from '../platform/theme';


const themeMaterial = platformTheme => createTheme({
  palette: {
    primary: {
      light: platformTheme.color.primary.light,
      main: platformTheme.color.primary.main,
      dark: platformTheme.color.primary.dark,
      contrastText: platformTheme.color.primary.contrastText,
    },
    secondary: {
      light: platformTheme.color.secondary.light,
      main: platformTheme.color.secondary.main,
      dark: platformTheme.color.secondary.dark,
      contrastText: platformTheme.color.secondary.contrastText,
    },
    success: {
      light: platformTheme.color.success.light,
      main: platformTheme.color.success.main,
      dark: platformTheme.color.success.dark,
      contrastText: platformTheme.color.success.contrastText,
    },
    error: {
      light: platformTheme.color.error.light,
      main: platformTheme.color.error.main,
      dark: platformTheme.color.error.dark,
      contrastText: platformTheme.color.error.contrastText,
    },
    action: {
      disabled: platformTheme.color.disabled.color,
      disabledBackground: platformTheme.color.disabled.backgroundColor,
    },
  },
  shape: {
    borderRadius: platformTheme.shape.borderRadius,
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: platformTheme.typography.fontFamily,
    fontSize: platformTheme.typography.fontSize,
    h1: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h1.fontSize,
      fontWeight: platformTheme.typography.h1.fontWeight,
    },
    h2: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h2.fontSize,
      fontWeight: platformTheme.typography.h2.fontWeight,
    },
    h3: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h3.fontSize,
      fontWeight: platformTheme.typography.h3.fontWeight,
    },
    h4: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h4.fontSize,
      fontWeight: platformTheme.typography.h4.fontWeight,
    },
    h5: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h5.fontSize,
      fontWeight: platformTheme.typography.h5.fontWeight,
    },
    h6: {
      fontFamily: platformTheme.typography.fontFamily,
      fontSize: platformTheme.typography.h5.fontSize,
      fontWeight: platformTheme.typography.h6.fontWeight,
    },
    button: {
      fontFamily: platformTheme.button.fontFamily,
      fontWeight: platformTheme.button.fontWeight,
      fontSize: platformTheme.button.fontSize,
      lineHeight: platformTheme.button.lineHeight,
      letterSpacing: platformTheme.button.letterSpacing,
      textTransform: platformTheme.button.textTransform,
    },
  },
  sidebar: {
    width: 180,
    closedWidth: 60,
  },
  overrides: {
    MuiDrawer: {
      paper: {
        minWidth: 0,
      },
      paperAnchorDockedLeft: {
        small: 10,
        medium: 20,
        large: 30,
        borderRight: 'none',
      },
    },
    MuiButton: {
      root: {
        minWidth: platformTheme.button.minWidth,
        color: platformTheme.color.grey.darker,
        '&:hover': {
          color: '#fff',
          backgroundColor: platformTheme.color.grey.darker,
        },
        borderRadius: `${platformTheme.button.borderRadius}px`,
        fontSize: platformTheme.button.fontSize,
        lineHeight: platformTheme.button.lineHeight,
      },
      contained: {
        color: '#fff',
        backgroundColor: platformTheme.color.grey.darker,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: platformTheme.color.primary.main,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: platformTheme.color.primary.light,
        },
      },
      textPrimary: {
        '&:hover': {
          color: '#ffff',
          backgroundColor: platformTheme.color.primary.main,
        },
      },
      textSecondary: {
        '&:hover': {
          color: '#ffff',
          backgroundColor: platformTheme.color.secondary.main,
        },
      },
      outlined: {
        borderColor: platformTheme.color.grey.darker,
      },
      outlinedPrimary: {
        '&:hover': {
          color: '#ffff',
          backgroundColor: platformTheme.color.primary.main,
        },
      },
      outlinedSecondary: {
        '&:hover': {
          color: '#ffff',
          backgroundColor: platformTheme.color.secondary.main,
        },
      },
      sizeLarge: {
        fontSize: platformTheme.button.fontSize,
      },
      sizeSmall: {
        minWidth: '0',
      },
    },
    MuiFormLabel: {
      root: {
        color: platformTheme.color.primary.light,
      },
    },
    MuiFormControl: {
      root: {
        marginTop: '10px',
      },
    },
    MuiInput: {
      root: {
        '&.Mui-error': {
          color: platformTheme.color.error.main,
        },
        '&.Mui-error .MuiIconButton-root': {
          color: platformTheme.color.error.main,
        },
      },
      underline: {
        '&:hover:not($disabled):after': {
          backgroundColor: platformTheme.color.primary.light,
        },
        '&:after': {
          borderBottom: `2px solid ${platformTheme.color.primary.light}`,
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid ${platformTheme.color.primary.dark}`,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
  },
});

export default themeMaterial(theme);
