import { GET_CLIENT_SECRET, OAUTH_API_URL } from './utils';

const urlOAuth = OAUTH_API_URL();
const clientSecret = GET_CLIENT_SECRET();
const getRefreshToken = ({ password }) => fetch(urlOAuth, {
  method: 'post',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  body: new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_CRED_CLIENT_ID,
    client_secret: clientSecret,
    scope: process.env.REACT_APP_SCOPE,
    refresh_token: password,
  }),
})
  .then(response => response.json())
  .then(res => res)
  .catch((error) => {
    throw error;
  });

export default getRefreshToken;
