import React from 'react';
import {
  Create, SimpleForm,
} from 'react-admin';

import ProductToolbar from './ProductToolbar';
import Form from './Form';
import {
  validateProductCreation,
} from '../../lib/utils/products';


const ProductCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateProductCreation} toolbar={<ProductToolbar isEdit={false} />}>
      <Form isEdit={false} />
    </SimpleForm>
  </Create>
);

export default ProductCreate;
