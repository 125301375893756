import React, { forwardRef } from 'react';
import {
  useLocale, useSetLocale, useTranslate,
} from 'react-admin';
import { MenuItem, ListItemIcon } from '@material-ui/core';
import Language from '@material-ui/icons/Language';


import useStyles from './SwitchLanguage.styles';
import LangSelector from './LangSelector';

const SwitchLanguage = forwardRef((props, ref) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const translate = useTranslate();

  const handleClose = (value) => {
    setAnchorEl(null);
    setLocale(value);
  };

  return (
    <>
      <MenuItem
        ref={ref}
        className={classes.menuItem}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <ListItemIcon className={classes.icon}>
          <Language />
        </ListItemIcon>
        {translate('app.selectLanguage')}
      </MenuItem>
      <LangSelector selectedValue={locale} anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
});

export default SwitchLanguage;
