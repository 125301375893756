import React from 'react';
import {
  BooleanField,
  DateField,
  TextField,
} from 'react-admin';

const CustomTextField = (props) => {
  const {
    resource, source, record, type,
  } = props;
  const testidName = `${resource}-${source}-${record.id}-td`;
  if (type === 'TextField') {
    return <TextField data-testid={testidName} {...props} />;
  }
  if (type === 'BooleanField') {
    return <BooleanField data-testid={testidName} {...props} />;
  }
  if (type === 'DateField') {
    return <DateField data-testid={testidName} {...props} />;
  }
  return null;
};

CustomTextField.defaultProps = {
  type: 'TextField',
};

export default CustomTextField;
