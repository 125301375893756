import { makeStyles } from '@material-ui/core/styles';

const MIDDLE_WITH_SPACING = 5;

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  icon: { minWidth: theme.spacing(MIDDLE_WITH_SPACING) },
}));

export default useStyles;
