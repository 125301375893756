import React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import Form from './Form';
import SurveyToolbar from './SurveyToolbar';

const SurveyCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="edit" toolbar={<SurveyToolbar isEdit={false} />}>
      <Form {...props} />
    </SimpleForm>
  </Create>
);

export default SurveyCreate;
