import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(selectedTheme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 480,
    marginTop: '6em',
    boxShadow: 'none',
  },
  header: {
    margin: '1em 1em 4em 1em',
  },
  logo: {
    margin: '1em 1em 2em 1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: selectedTheme.palette.secondary.main,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '4em 1em 1em 1em',
    justifyContent: 'center',
  },
  resetPassword: {
    textAlign: 'center',
    cursor: 'pointer',
    fontFamily: selectedTheme.typography.fontFamily,
    color: selectedTheme.palette.primary.main,
  },
}));

export default useStyles;
