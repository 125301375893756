import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: () => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
  }),
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    width: '140px',
  },
}));

export default useStyles;
