import React from 'react';
import UserTitle from './UserTitle';
import ProfileController from './ProfileController';
import ProfileForm from './ProfileForm';

const UserDetailProfile = props => (
  <ProfileController undoable={false} title={<UserTitle />} {...props}>
    <ProfileForm />
  </ProfileController>
);

export default UserDetailProfile;
