import frenchMessages from 'ra-language-french';

export default {
  ...frenchMessages,
  app: {
    hello: 'Salut, %{name}!',
    selectLanguage: 'Choisir la langue',
  },
  resources: {
    promotions: {
      name: 'Promotion |||| Promotions',
      fields: {
        status: 'Statut',
      },
    },
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        name: 'Nom',
        role: 'Rôle',
      },
    },
    comments: {
      name: 'Commentaire |||| Commentaires',
    },
  },
  image: {
    success: 'Image uploaded successfully',
    tryagin: 'Some error in upload, please try again',
    error: 'upload image error',
    butonText: 'Ajouter une image',
    promotionImage: 'Promotion Image',
    ImageDropLabel: 'Drop your image here',
    deleteWarning: ' Do you really want to remove the image?',
    resolutionError: 'unsupported resolution',
    delete: {
      success: 'Image removed successfully',
      tryAgain: 'Some error in removing, please try again',
      error: 'remove image error',
    },
  },
  promotion: {
    list: {
      search: 'Recherche',
    },
    form: {
      name: 'Nom',
    },
    edit: {
      title: 'Article "%{title}"',
      name: 'Prenom',
      type: 'Type',
      description: 'La description',
      legalText: 'Text légal',
      couponCode: 'Code promo',
      status: 'Statu',
      startAt: 'Début',
      endAt: 'Final',
    },
    filter: {
      name: 'Prenom',
      status: 'Statut',
      startDate: 'Début',
      endDate: 'Final',
    },
    status: {
      changeStatusTitle: 'Are you sure you want to change the status of this promotion',
      changeStatusPositive: 'Yes',
      changeStatusNegative: 'No',
    },
  },
  user: {
    list: {
      search: 'Recherche',
      username: 'Username',
      name: 'Nom',
      lastName: 'Nom de familie',
      active: 'Active',
      activatedAt: 'Date d\'activation',
    },
    form: {
      name: 'Nom',
    },
    edit: {
      title: 'Utilisateur "%{title}"',
      username: 'Email',
      name: 'Prenom',
      lastname: 'Nom de familie',
      activated: 'Activé',
      conditions: 'Termes et conditions',
      createdAt: 'Créé le',
      updatedAt: 'Mis à jour le',
      activatedAt: 'Activé le',
    },
    tabs: {
      user: 'Détails',
      orders: 'Commandes',
    },
  },
  orders: {
    list: {
      promotionId: 'ID Promo',
      promotionName: 'Nom Promo',
      promotionKey: 'Key Promo',
      requestedAt: 'Date de la demande',
      expiredAt: "Date d'expiration",
      status: 'Statut',
    },
    filter: {
      promotionName: 'Nom Promo',
    },
  },
  comment: {
    edit: {
      title: 'Détail',
      addImage: 'Ajouter une image',
      ean: 'EAN',
      author: 'Auteur',
      username: 'Username',
      rating: 'Evaluation',
      createdAt: 'Créé le',
      status: 'Statut',
      certified: 'Certifié',
      text: 'Commentaire',
    },
    list: {
      ean: 'EAN',
      author: 'Auteur',
      username: 'Username',
      rating: 'Evaluation',
      createdAt: 'Créé le',
      status: 'Statut',
      certified: 'Certifié',
      text: 'Commentaire',
      anonymous: 'Anonyme',
      addEan: 'Veuillez introduire un EAN',
    },
  },
  blacklist: {
    save: 'Enregistrer',
    add: 'Ajouter un mot',
    filter: 'Rechercher un mot',
    questionRemove: '¿Supprimer %{selectedWord}?',
    addQuestion: 'Quel mot souhaitez-vous ajouter?',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    remove: 'Supprimer',
    existingTerm: 'Ce terme existe déjà',
  },
};
