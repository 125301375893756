import React from 'react';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import {
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    display: 'inline-block',
  },
});

const LinkTextField = ({
  basePath, record = {}, source, permissions, path, defaultKey, ...rest
}) => {
  const classes = useStyles();
  const defaultKeyTo = defaultKey || 'id';
  let to;

  if (path && defaultKey) {
    to = `${path}/${record[defaultKeyTo]}`;
  } else {
    to = (path) || `${linkToRecord(basePath, record && record[defaultKeyTo])}`;
  }

  return (
    <TextField
      className={classes.link}
      source={source}
      record={record}
      component={Link}
      to={to}
      {...rest}
    />
  );
};
export default LinkTextField;
