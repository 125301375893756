import { TokenRefreshLink } from 'apollo-link-oauth-token-refresh';

import getRefreshToken from '../lib/utils/getRefreshToken';
import { isExpired, calculateExpirationTime } from '../lib/utils/date';

const isValidExpiredToken = () => {
  const expires = localStorage.getItem('expires');
  return expires === null || !isExpired(expires);
};
const RefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: isValidExpiredToken,
  fetchAccessToken: () => {
    const refreshToken = localStorage.getItem('refresh') || '';
    return getRefreshToken({ password: refreshToken });
  },
  handleResponse:
    () => (response) => {
      if (response?.error) {
        return false;
      }
      const {
        token_type: type, access_token: token, expires_in: expires, refresh_token: refresh,
      } = response;
      if (token) {
        const tokenRefreshed = `${type} ${token}`;
        const expirationDate = calculateExpirationTime(expires);
        localStorage.setItem('token', tokenRefreshed);
        localStorage.setItem('expires', expirationDate);
        localStorage.setItem('refresh', refresh);
        return true;
      }
      localStorage.setItem('token', '');
      localStorage.setItem('expires', '');
      localStorage.setItem('refresh', '');
      return false;
    },
  handleError: () => {
    localStorage.setItem('token', '');
    localStorage.setItem('expires', '');
    localStorage.setItem('refresh', '');
  },
});

export default RefreshLink;
