import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
  TextField, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import surveyStyles from '../surveyStyles';

const RadioCheckbox = (props) => {
  const classes = surveyStyles();
  const {
    answersAvailable,
    options,
    title,
    id,
    changeQuestionTitle,
    changeQuestionAnswerValue,
    removeQuestionAnswer,
    addQuestionAnswer,
    recordQuestions,
    setRecordQuestions,
  } = props;

  const onChangeTitle = (e) => {
    changeQuestionTitle(id, e.target.value);
  };

  const onChangeAnswerValue = (index, value) => {
    changeQuestionAnswerValue(id, index, value);
  };

  const toggleMultipleValue = (value) => {
    const target = recordQuestions?.find(item => item.id === id);
    const modified = {
      ...target,
      options: {
        isMultiple: value,
      },
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  return (
    <div className={classes.questionListContainer}>
      <div style={{ width: '100%' }}>
        <Accordion>
          <AccordionSummary
            className={classes.questionListAccordion}
            expandIcon={<ExpandMoreIcon />}
          >
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={<TextField defaultValue={title} label="Title" onChange={onChangeTitle} fullWidth />}
              label=""
            />
          </AccordionSummary>
          <AccordionDetails className={classes.questionListAnswersWrapper}>
            <Typography variant="h4" color="primary">Answers available</Typography>
            {answersAvailable && answersAvailable.map((answer, indexN) => {
              const keyString = `${indexN}`;
              return (
                <div className={classes.answersContainer} key={`answer_${keyString}`}>
                  <TextField
                    defaultValue={answer?.title}
                    variant="outlined"
                    onChange={e => onChangeAnswerValue(indexN, e.target.value)}
                    fullWidth
                  />
                  <RemoveIcon
                    className={classes.pointer}
                    onClick={() => removeQuestionAnswer(id, indexN)}
                  />
                </div>
              );
            })}
            <AddCircleIcon style={{ margin: '0 auto' }} className={classes.pointer} onClick={() => addQuestionAnswer(id)} />
          </AccordionDetails>
        </Accordion>
      </div>
      <FormControlLabel
        control={<Switch checked={options?.isMultiple} name={`multiple_${id}`} onChange={e => toggleMultipleValue(e.target.checked)} />}
        label="Multiple"
      />
    </div>
  );
};

export default RadioCheckbox;
