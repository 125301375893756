import React from 'react';
import {
  Layout, UserMenu, useTranslate,
} from 'react-admin';
import { MenuItem } from '@material-ui/core';

import MyAppBar from '../AppBar/AppBar';

const MyUserMenu = (props) => {
  const translate = useTranslate();
  const identity = JSON.parse(localStorage.getItem('auth'));
  return (
    <UserMenu {...props}>
      <MenuItem>{translate('app.hello', { name: (identity) ? identity.username : 'USERNAME' })}</MenuItem>
    </UserMenu>
  );
};

const MyAppBarUpdated = props => <MyAppBar {...props} userMenu={<MyUserMenu />} />;

export default props => <Layout {...props} appBar={MyAppBarUpdated} />;
