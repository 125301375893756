import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Menu } from '@material-ui/core';

const langs = [
  { key: 'es', name: 'Español' },
  { key: 'en', name: 'English' },
  { key: 'fr', name: 'Français' },
];

function LangSelector(props) {
  const { onClose, selectedValue, anchorEl } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      {langs.map(lang => (
        <MenuItem onClick={() => handleListItemClick(lang.key)} key={lang.key}>
          {lang.name}
        </MenuItem>
      ))}
    </Menu>
  );
}

LangSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.element.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default LangSelector;
