import enMessages from './en';
import frMessages from './fr';
import esMessages from './es';

const messages = {
  fr: { ...frMessages },
  en: { ...enMessages },
  es: { ...esMessages },
};
export default messages;
