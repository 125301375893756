import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MuiTab from '@material-ui/core/Tab';
import { useTranslate } from 'ra-core';
import classnames from 'classnames';

const Tab = ({
  basePath,
  children,
  contentClassName,
  context,
  className,
  icon,
  label,
  resource,
  value,
  ...rest
}) => {
  const translate = useTranslate();

  const renderHeader = () => (
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      className={classnames('show-tab', className)}
      {...({ component: Link, to: value })}
      {...rest}
    />
  );

  const renderContent = () => (
    <div className={contentClassName}>
      {React.Children.map(children, field => (field && isValidElement(field) ? (
        <div
          key={field.props.source}
          className={classnames(
            'ra-field',
            `ra-field-${field.props.source}`,
            field.props.className,
          )}
        >
          {
              React.cloneElement(field, {
                ...field.props,
              })
            }
        </div>
      ) : null))}
    </div>
  );

  return context === 'header' ? renderHeader() : renderContent();
};


Tab.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.oneOf(['header', 'content']),
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  value: PropTypes.string,
};


export default Tab;
