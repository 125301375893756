import React, { useState, useLayoutEffect, useMemo } from 'react';
import {
  ListButton,
  useTranslate,
  useEditController,
} from 'react-admin';
import uuid from 'react-uuid';
import {
  Box,
  FormControlLabel, InputLabel,
  Switch,
  TextField, Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from 'lodash';

import { parseQuestionsNodeInput, typeDictionary } from '../../lib/utils/surveys';
import surveyStyles from './surveyStyles';
import Questions from './Questions/Questions';
import ConfirmDelete from './Questions/ConfirmDelete';

const Form = (props) => {
  const { isEdit, setInput } = props;
  const t = useTranslate();
  const { record } = useEditController(props);
  const [surveyName, setSurveyName] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [random, setRandom] = useState(false);
  const [recordQuestions, setRecordQuestions] = useState(undefined);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [titleToDelete, setTitleToDelete] = useState('');
  const [idToDelete, setIdToDelete] = useState(undefined);
  const classes = surveyStyles();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

  const changeQuestionType = (id, type) => {
    const modified = {
      id,
      type,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const changeQuestionTitle = (id, title) => {
    const target = recordQuestions?.find(item => item.id === id);
    const modified = {
      ...target,
      title,
    };

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === modified.id ? modified : item);

    setRecordQuestions(result);
  };

  const handleClickDeleteQuestion = (id) => {
    const find = recordQuestions?.find(item => item.id === id);
    setIdToDelete(id);
    setTitleToDelete(find?.title);
    setConfirmDialog(true);
  };

  const deleteQuestion = (id) => {
    const filter = recordQuestions?.filter(item => item.id !== id);

    setRecordQuestions(filter);
  };

  const createQuestion = () => {
    const filter = recordQuestions ? [...recordQuestions] : [];
    const defaultValues = {
      type: 'radio-checkbox',
      id: uuid(),
      answersAvailable: [],
      title: '',
    };
    filter.push({ ...defaultValues });
    setRecordQuestions(filter);
  };

  const changeQuestionAnswerValue = (questionId, index, value) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    target.answersAvailable[index].title = value;

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === target.id ? target : item);

    setRecordQuestions(result);
  };

  const removeQuestionAnswer = (questionId, index) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    delete target.answersAvailable[index];

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === target.id ? target : item);

    setRecordQuestions(result);
  };

  const addQuestionAnswer = (questionId) => {
    const target = recordQuestions?.find(item => item.id === questionId);
    const answers = target?.answersAvailable ?? [];
    answers.push({ title: '' });
    target.answersAvailable = answers;

    /* eslint-disable-next-line */
    const result = recordQuestions?.map(item => item.id === target.id ? target : item);

    setRecordQuestions(result);
  };

  const toggleStatus = () => {
    setStatus(prev => !prev);
  };

  const toggleRandom = () => {
    setRandom(prev => !prev);
  };

  const parsedInput = useMemo(() => ({
      id: record?.id,
      input: {
        active: status,
        name: surveyName,
        options: {
          randomEnabled: random,
        },
        questions: parseQuestionsNodeInput(recordQuestions),
      },
    }
  ), [record, status, surveyName, random, recordQuestions]);

  useLayoutEffect(() => {
    setInput(parsedInput);
  }, [parsedInput]);

  useLayoutEffect(() => {
    if (record) {
      const {
        active, name, options, questions,
      } = record;

      setSurveyName(name);
      setStatus(active);
      setRandom(options?.randomEnabled);

      if (questions) {
        const parsed = [];
        questions.map((question) => {
          const parsedQuestion = _.omit(question, '__typename');
          if (typeof parsedQuestion === 'object') {
            /* eslint-disable */
            for (const [key, value] of Object.entries(parsedQuestion)) {
              parsed.push({
                type: typeDictionary(key),
                ...value,
              });
            }
            /* eslint-enable */
          }

          return true;
        });
        setRecordQuestions(parsed);
      }
    }
  }, [record]);

  return (
    <>
      <ListButton style={{ width: '60px' }} />
      <h3 style={{ ...globalFont }}>{t('surveys.edit.info')}</h3>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%', gap: '0 25px' }}>
        {isEdit && (
          <Box flex={1} style={{ alignSelf: 'center' }}>
            <TextField label="ID" value={record?.id} fullWidth disabled />
          </Box>
        )}
        <Box flex={1} style={{ alignSelf: 'center' }}>
          <TextField label="Name" value={surveyName} onChange={e => setSurveyName(e.target.value)} fullWidth />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center', textAlign: 'center' }}>
          <InputLabel>Status</InputLabel>
          <FormControlLabel
            control={<Switch checked={status} onChange={toggleStatus} color="primary" />}
            label={t('surveys.edit.status.active')}
          />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center', textAlign: 'center' }}>
          <InputLabel>Random</InputLabel>
          <FormControlLabel
            control={<Switch checked={random} onChange={toggleRandom} color="primary" />}
            label={t('surveys.edit.options.random')}
          />
        </Box>
      </Box>
      <h3 style={{ ...globalFont }}>{t('surveys.edit.questions')}</h3>
      {recordQuestions && (
        <Questions
          recordQuestions={recordQuestions}
          setRecordQuestions={setRecordQuestions}
          changeQuestionType={changeQuestionType}
          changeQuestionTitle={changeQuestionTitle}
          deleteQuestion={handleClickDeleteQuestion}
          changeQuestionAnswerValue={changeQuestionAnswerValue}
          removeQuestionAnswer={removeQuestionAnswer}
          addQuestionAnswer={addQuestionAnswer}
        />
      )}
      <div className={classes.addQuestionContainer}>
        <AddCircleIcon className={classes.pointer} onClick={() => createQuestion()} />
        <Typography onClick={() => createQuestion()}>Add question</Typography>
      </div>
      <ConfirmDelete
        id={idToDelete}
        title={titleToDelete}
        open={confirmDialog}
        setOpen={setConfirmDialog}
        handleSubmit={deleteQuestion}
      />
    </>
  );
};

export default Form;
