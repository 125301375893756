import React from 'react';
import { useTranslate } from 'react-admin';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { QUESTION_TYPES, selectTypeValues } from '../../../lib/utils/surveys';
import RadioCheckbox from './RadioCheckbox';
import surveyStyles from '../surveyStyles';

const Question = (props) => {
  const t = useTranslate();
  const {
    id,
    type,
    changeQuestionType,
    deleteQuestion,
  } = props;
  const classes = surveyStyles();

  const onChangeType = (e) => {
    changeQuestionType(id, e.target.value);
  };

  return (
    <div className={classes.questionContainer}>
      <DragIndicatorIcon className={classes.pointer} />
      <div>
        <InputLabel>Type</InputLabel>
        <Select displayEmpty value={type} name="type" onChange={onChangeType}>
          {selectTypeValues.map(value => (
            <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
          ))}
        </Select>
      </div>
      {type === '' && <p>{t('surveys.edit.noType')}</p>}
      {type === QUESTION_TYPES.LIST && <RadioCheckbox {...props} />}
      <div>
        <DeleteIcon className={classes.pointer} onClick={() => deleteQuestion(id)} />
      </div>
    </div>
  );
};

export default Question;
