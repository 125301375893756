/* eslint-disable import/prefer-default-export */
// Based on https://www.npmjs.com/package/json-to-graphql-query
const ZERO = 0;
const ONE = 1;
const LINE = 4;

function getIndent(level) {
  return Array(level * LINE + ONE).join(' ');
}

function convertObject(node, level, output, options) {
  Object.keys(node).forEach((key) => {
    let value = node[key];
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        value = value.find(item => item && typeof item === 'object');
        if (!value) {
          output.push([`${key}`, level]);
          return;
        }
      }
      const fieldCount = Object.keys(value).length;
      const subFields = fieldCount > ZERO;
      const token = `${key}`;

      output.push([token + (subFields ? ' {' : ''), level]);
      convertObject(value, level + ONE, output, options);

      if (subFields) {
        output.push(['}', level]);
      }
    } else if (value) {
      output.push([`${key}`, level]);
    }
  });
}

const buildFieldsGraphQL = (object, options = {}) => {
  if (!object || typeof object !== 'object') {
    throw new Error('query object not specified');
  }
  if (Object.keys(object).length === ZERO) {
    throw new Error('query object has no data');
  }

  const objectLines = [];
  convertObject(object, ZERO, objectLines, options);
  let output = '';
  objectLines.forEach(([line, level]) => {
    if (options.pretty) {
      if (output) {
        output += '\n';
      }
      output += getIndent(level) + line;
    } else {
      if (output) {
        output += ' ';
      }
      output += line;
    }
  });
  return output;
};

export { buildFieldsGraphQL };
