import uuid from 'react-uuid';

const SESSION_UUID = `session-uuid_${process.env.REACT_APP_ID}`;
const VERSION = 'APP_VERSION';

const getSessionUuid = () => sessionStorage.getItem(SESSION_UUID);
const setSessionUuid = value => sessionStorage.setItem(SESSION_UUID, value);

const getAppVersion = () => localStorage.getItem(VERSION);

const getSessionKey = () => {
  if (getSessionUuid()) {
    return getSessionUuid();
  }

  const key = uuid();
  setSessionUuid(key);

  return key;
};

export { getAppVersion, getSessionKey };
