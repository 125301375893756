import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GET_CLIENT_SECRET, GRAPHQL_API_URL, OAUTH_API_URL } from '../lib/utils/utils';
import { calculateExpirationTime } from '../lib/utils/date';

const _ = require('lodash');


// Change this to be your own authentication token URI.
const apiUrl = GRAPHQL_API_URL;

// Default Login Text Error
// const DEFAULT_LOGIN_ERROR = 'Sorry the data does not match any user';

/* ROLE is implemented for fixing the  tab switching permisison lost issue.
  If we continously switch promotion and user tabs, permission object becomes null and
  which cause show page display for admin users
*/
let ROLE = null;

const ROLE_ADMIN = 'ADMIN';

const AUTH_EXCEPTION = [
  'UnAuthenticatedException',
  'UnAuthenticated',
  'BadClientException',
  'ForbiddenException',
  'TokenExpiredException',
];

const createHttpLink = authorization => ({
  uri: apiUrl,
  headers: {
    Authorization: authorization,
  },
});

const createClient = httpLink => new ApolloClient({
  link: new HttpLink(httpLink),
  cache: new InMemoryCache(),
});

const getUser = async (token) => {
  const httpLink = createHttpLink(`Bearer ${token}`);
  const client = createClient(httpLink);

  return client.query({
    query: gql`{
          me {
            role,
            username,
            name,
            lastName
          }
        }`,
  }).then(({ data }) => data.me);
};


export function validateUser(username, password) {
  return fetch(OAUTH_API_URL(), {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      grant_type: process.env.REACT_APP_PASS_GRANT_TYPE,
      client_id: process.env.REACT_APP_CRED_CLIENT_ID,
      client_secret: GET_CLIENT_SECRET(),
      scope: process.env.REACT_APP_SCOPE,
      username,
      password,
    }),
  }).then(response => response.json())
    .then(res => res).catch((error) => {
      throw error;
    });
}

const authProvider = {
  login: ({ username, password }) => fetch(OAUTH_API_URL(), {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      client_id: process.env.REACT_APP_CRED_CLIENT_ID,
      client_secret: GET_CLIENT_SECRET(),
      scope: process.env.REACT_APP_SCOPE,
      grant_type: process.env.REACT_APP_PASS_GRANT_TYPE,
      username,
      password,
    }),
  }).then(response => response.json())
    .then((res) => {
      const {
        access_token: token, expires_in: expires, refresh_token: refresh,
      } = res;
      return getUser(token).then((user) => {
        if (user && user.role === ROLE_ADMIN) {
          const expirationDate = calculateExpirationTime(expires);
          localStorage.setItem('token', token); // The token is stored in the browser's local storage
          localStorage.setItem('role', user.role);
          localStorage.setItem('auth', JSON.stringify(user));
          localStorage.setItem('expires', expirationDate);
          localStorage.setItem('refresh', refresh);
          localStorage.removeItem('not_authenticated');
          ROLE = user.role;
          return Promise.resolve(user);
        }
        return Promise.reject(new Error());
      });
    }).catch((error) => {
      throw error;
    }),

  logout: () => {
    ROLE = null;
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('auth');
    localStorage.setItem('not_authenticated', true);
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  checkError: (error) => {
    const errorsCode = (error.graphQLErrors)
      ? error.graphQLErrors.map(err => err.extensions.code)
      : null;

    if (errorsCode && _.includes(AUTH_EXCEPTION, errorsCode[0])) {
      ROLE = null;
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('auth');
      localStorage.setItem('not_authenticated', true);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    if (ROLE) {
      return Promise.resolve(ROLE);
    }
    const role = localStorage.getItem('role');
    ROLE = role;
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
