import _ from 'lodash';

export const QUESTION_TYPES = {
  LIST: 'radio-checkbox',
};

export const selectTypeValues = [
  { id: '', name: '-' },
  { id: QUESTION_TYPES.LIST, name: 'Radio/Checkbox' },
];

export const typeDictionary = (key) => {
  switch (key) {
    case 'questionList':
      return QUESTION_TYPES.LIST;
    default:
      return undefined;
  }
};

const typeDictionaryForInput = (key) => {
  switch (key) {
    case QUESTION_TYPES.LIST:
      return 'questionList';
    default:
      return undefined;
  }
};

const parseInputByType = (input) => {
  switch (input.type) {
    case QUESTION_TYPES.LIST:
      return {
        id: input.id,
        answersAvailable: input?.answersAvailable?.map(answer => _.omit(answer, '__typename')),
        options: _.omit(input.options, '__typename'),
        title: input.title,
      };
    default:
      return null;
  }
};

export const parseQuestionsNodeInput = (input) => {
  if (input) {
    const parsedInput = [];

    input.map(item => parsedInput.push({
        [typeDictionaryForInput(item.type)]: {
          ...parseInputByType(item),
        },
      }));

    return parsedInput;
  }

  return input;
};
