import spanishMessages from './ra/spanish';

export const messages = {
  ...spanishMessages,
  app: {
    hello: 'Hola, %{name}!',
    selectLanguage: 'Seleciona idioma',
  },
  resources: {
    promotions: {
      name: 'Promoción |||| Promociones',
      fields: {
        status: 'Estado',
      },
    },
    users: {
      name: 'Usuario |||| Usuarios',
      fields: {
        name: 'Nombre',
        role: 'Role',
      },
    },
    comments: {
      name: 'Comentario |||| Comentarios',
    },
    seasons: {
      name: 'Temporada |||| Temporadas',
    },
  },
  image: {
    success: 'Image uploaded successfully',
    tryagin: 'Some error in upload, please try again',
    error: 'upload image error',
    butonText: 'Subir imagen',
    promotionImage: 'Promotion Image',
    ImageDropLabel: 'Drop your image here',
    deleteWarning: ' Do you really want to remove the image?',
    resolutionError: 'unsupported resolution',
    delete: {
      success: 'Image removed successfully',
      tryAgain: 'Some error in removing, please try again',
      error: 'remove image error',
    },
  },
  promotion: {
    list: {
      search: 'Búsqueda',
      id: 'Id',
      ean: 'EAN',
      name: 'Nombre',
      startDate: 'Fecha de inicio',
      state: 'Estado',
    },
    form: {
      name: 'Name',
      type: 'Tipo',
    },
    edit: {
      title: 'Promoción "%{title}"',
      name: 'Nombre',
      type: 'Tipo',
      description: 'Descripción',
      legalText: 'Texto legal',
      couponCode: 'Código de cupón',
      status: 'Estatus',
      startAt: 'Fecha de inicio',
      endAt: 'Fecha final',
    },
    filter: {
      name: 'Nombre',
      status: 'Estatus',
      startDate: 'Inicio',
      endDate: 'Final',
    },
    status: {
      changeStatusTitle: 'Are you sure you want to change the status of this promotion',
      changeStatusPositive: 'Yes',
      changeStatusNegative: 'No',
    },
  },
  user: {
    list: {
      search: 'Búsqueda',
      username: 'Nombre de usuario',
      name: 'Nombre',
      lastName: 'Apellido',
      active: 'Activo',
      activatedAt: 'Fecha de activación',
    },
    form: {
      name: 'Nombre',
    },
    edit: {
      title: 'Usuario "%{title}"',
      addImage: 'Agregar imagen',
      username: 'Email',
      name: 'Nombre',
      lastname: 'Apellido',
      activated: 'Activado',
      conditions: 'Condiciones',
      createdAt: 'Fecha de creación',
      updatedAt: 'Fecha de actualización',
      activatedAt: 'Fecha de activación',
    },
    filter: {
      active: 'Activo',
      username: 'Nombre de Usuario',
      activatedAt: 'Fecha de activación',
    },
    tabs: {
      user: 'Detalle',
      orders: 'Pedidos',
    },
  },
  orders: {
    list: {
      promotionId: 'Id Promoción',
      promotionName: 'Nombre Promoción',
      promotionKey: 'Key Promoción',
      requestedAt: 'Fecha de solicitud',
      expiredAt: 'Fecha de expiración',
      status: 'Estado',
    },
    filter: {
      promotionName: 'Nombre Promoción',
    },
  },
  comment: {
    edit: {
      title: 'Detalle',
      ean: 'EAN',
      author: 'Autor',
      username: 'Usuario',
      rating: 'Rating',
      createdAt: 'Fecha de creación',
      status: 'Estatus',
      certified: 'Certificado',
      text: 'Comentario',
    },
    list: {
      ean: 'EAN',
      author: 'Autor',
      username: 'Usuario',
      rating: 'Nota',
      createdAt: 'Fecha de creación',
      status: 'Estatus',
      certified: 'Certificado',
      text: 'Comentario',
      anonymous: 'Usuario anónimo',
      addEan: 'Por favor agrega un EAN para listar comentarios',
    },
  },
  blacklist: {
    save: 'Guardar lista',
    add: 'Agregar nueva palabra',
    filter: 'Filtrar palabra',
    questionRemove: '¿Borrar %{selectedWord}?',
    addQuestion: '¿Qué palabra quieres agregar?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    remove: 'Borrar',
    existingTerm: 'Este término ya existe',
  },
  seasons: {
    list: {
      id: 'ID',
      name: 'Nombre',
      startDate: 'Fecha inicio',
      endDate: 'Fecha fin',
      accolade: 'Distinción',
    },
    show: {
      title: 'Season',
      id: 'ID',
      name: 'Nombre',
      startDate: 'Fecha inicio',
      endDate: 'Fecha fin',
      accolade: 'Distincion',
    },
  },
};

export default messages;
